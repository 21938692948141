import Vue from 'vue';
import Router from 'vue-router';
import login from '../views/login.vue';
import mapInfo from '../views/mapInfo.vue';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/login'
        },
        {
            path: '/login',
            name: 'login',
            meta: { title: '箱体可视化2.0' },
            component: login
        },
        {
            path: '/mapInfo',
            name: 'mapInfo',
            meta: { title: '箱体地图展示' },
            component: mapInfo
        }
    ]
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
    } else {
        let token = localStorage.getItem('Authorization');
        if (token === 'null' || token === '') {
            next('/login');
        } else {
            next();
        }
    }
    if (to.path == '/mapInfo' && from.path == '/login' && from.path != '/') {
        window.location.reload();
    }
});

export default router;