import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import BaiduMap from 'vue-baidu-map';
import api from './http/index';
import preventClick from './extend/controlClickState';
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.use(BaiduMap, {
    ak: 'a6KWQx6LtXyRt19N3pCLVMGLgyBtsOst'
});
Vue.use(api);
Vue.use(preventClick); // 设置qrcode点击按钮刷新频率
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(ElementUI);
require('./extend/utils');  //公共方法
require('./extend/filter'); //过滤器
import { Toast } from 'vant';
Vue.use(Toast);
import { Dialog } from 'vant';
Vue.prototype.$Dialog = Dialog
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');