// import Vue from "vue";
import axios from "axios"; // 注意先安装哦
// import { from } from "core-js/core/array";
import router from '../router/index';
import config from "./config.js"; // 倒入默认配置
// import { msgBox } from 'element-ui';
// import qs from 'qs' // 序列化请求数据，视服务端的要求
// import store from "../store";
import { Toast } from 'vant';

export default function $axios(options) {
    return new Promise((resolve, reject) => {

        const instance = axios.create({
            baseURL: config.baseURL,
            headers: {
                Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
                Accept: "application/json",
                // "Access-Control-Allow-Origin": '*'
                // "Access-Token": window.localStorage.getItem("access_token").toString()
            },
            transformResponse: [function () { }],
        });
        // request 拦截器
        instance.interceptors.request.use(
            (config) => {
                if (config.isLoading !== false) {
                    // 如果配置了isLoading: false，则不显示loading

                }
                // 带上 token , 可以结合 vuex 或者重 localStorage
                // config.headers["Content-Type"] = "application/json";
                config.headers["Tenant-Id"] = "000000";
                config.headers["grantType"] = "password";
                // if (window.localStorage.getItem("access_token")) {
                //     config.headers["Authorization"] = window.localStorage
                //         .getItem("access_token")
                //         .toString(); // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
                // } else {
                //     // 重定向到登录页面
                // }
                if (localStorage.getItem('Authorization')) {
                    config.headers["blade-auth"] = localStorage.getItem('Authorization');
                }

                // Tip: 3
                // 根据请求方法，序列化传来的参数，根据后端需求是否序列化
                if (
                    config.method.toLocaleLowerCase() === "post" ||
                    config.method.toLocaleLowerCase() === "put" ||
                    config.method.toLocaleLowerCase() === "delete"
                ) {
                    // config.data = qs.stringify(config.data);
                }

                return config;
            },
            (error) => {

                // 请求错误时做些事(接口错误、超时等)
                //  1.判断请求超时
                if (
                    error.code === "ECONNABORTED" &&
                    error.msg.indexOf("timeout") !== -1
                ) {
                    console.log(
                        "根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案"
                    );
                    // return service.request(originalRequest);//例如再重复请求一次
                }
                //  2.需要重定向到错误页面
                const errorInfo = error.response;
                if (errorInfo) {
                    // error =errorInfo.data//页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
                    // const errorStatus = errorInfo.status // 404 403 500 ... 等
                    // router.push({
                    //   path: `/error/${errorStatus}`
                    // })
                }
                return Promise.reject(error); // 在调用的那边可以拿到(catch)你想返回的错误信息
            }
        );

        // response 拦截器
        instance.interceptors.response.use(
            (response) => {
                let data;
                // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
                if (response.data == undefined) {
                    data = JSON.parse(response.request.responseText);
                } else {
                    data = response.data;
                }
                // 根据返回的code值来做不同的处理（和后端约定）
                switch (data.code) {
                    case "":
                        break;
                    default:
                }
                // 若不是正确的返回code，且已经登录，就抛出错误
                // const err = new Error(data.description)

                // err.data = data
                // err.response = response

                // throw err
                return data;
            },
            (err) => {

                if (err && err.response) {
                    switch (err.response.status) {
                        case 400:
                            let errRequest = err.response.request;
                            let errData = JSON.parse(errRequest.response);
                            // console.log('erreerrrrr', errData.error_description);
                            // $msgErr('您的请求错误，请检索输入的内容');
                            err.msg = errData.error_description;
                            break;

                        case 401:
                            //授权过期 跳转登录页面
                            localStorage.removeItem('Authorization');
                            router.replace({
                                path: "/login",
                            });
                            err.msg = "未授权，请登录";
                            break;

                        case 403:
                            err.msg = "拒绝访问";
                            break;

                        case 404:
                            err.msg = `请求地址不存在，请联系管理员！！`;
                            ;
                            break;

                        case 408:
                            err.msg = "请求超时";
                            break;

                        case 500:
                            err.msg = "服务器内部错误";
                            break;

                        case 501:
                            err.msg = "服务未实现,请联系管理员！！";

                            break;

                        case 502:
                            err.msg = "网关错误,请联系管理员！！";

                            break;

                        case 503:
                            err.msg = "服务不可用,请联系管理员！！";

                            break;

                        case 504:
                            err.msg = "网关超时,请联系管理员！！";

                            break;

                        case 505:
                            err.msg = "HTTP版本不受支持,请联系管理员！！";

                            break;

                        default:
                    }
                }

                return Promise.reject(err); // 返回接口返回的错误信息
            }
        );

        //请求处理
        instance(options)
            .then((res) => {
                resolve(res);
                return false;
            })
            .catch((error) => {
                reject(error);
            });
    });
}