import axios from './api' // 倒入 api

/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
export const query = params => {
    return axios({
        url: '/query',
        method: 'get',
        params
    })
}

export const post = (url, params) => {
    return axios({
        url: url,
        method: 'post',
        data: params
    })
}

export const put = (url, params) => {
    return axios({
        url: url,
        method: 'put',
        data: params
    })
}

export const del = (url, params) => {
    return axios({
        url: url,
        method: 'delete',
        params
    })
}

export const get = (url, params) => {
    return axios({
        url: url,
        method: 'get',
        params
    })
}

export const mock = params => {
    return axios({
        url: '/mock',
        method: 'get',
        params
    })
}

export const upload = (url, formdata) => {
    let config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    }
    return axios({
        url: url,
        method: 'post',
        formdata: formdata,
        config: config
    })
}

// 默认全部倒出
// 根据需要进行
export default {
    query,
    post,
    put,
    del,
    get,
    mock,
    upload
}