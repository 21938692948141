<template>
  <div class="login">
    <div class="bgd">
      <canvas id="myCanvas" :width="width" :height="height"> </canvas>
    </div>
    <div class="loginBox">
      <h4>
        <i class="el-icon-map-location"></i>
        箱体地图可视化2.0
      </h4>
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-width="0px"
      >
        <el-form-item label="" prop="userName" style="margin-top: 40px">
          <el-row>
            <el-col :span="2">
              <i class="el-icon-user-solid"></i>
            </el-col>
            <el-col :span="22" class="borderRadius">
              <el-input
                class="inps"
                placeholder="请输入用户名"
                v-model="loginForm.userName"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="passWord">
          <el-row>
            <el-col :span="2">
              <i class="el-icon-shopping-bag-1"></i>
            </el-col>
            <el-col :span="22" class="borderRadius">
              <el-input
                type="password"
                class="inps"
                placeholder="请输入密码"
                :show-password="true"
                v-model="loginForm.passWord"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="margin-top: 55px; margin-left: 10px">
          <el-button
            type="primary"
            round
            class="submitBtn"
            @click="submitForm('loginForm')"
            >登陆
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import BasePage from "../base/BasePage";
import Config from "../config";
// import Vconsole from "vconsole";
import { mapMutations } from "vuex";
export default {
  name: "login",

  data() {
    return {
      userToken: "",
      canvas: null,
      context: null,
      stars: [], //星星数组
      shadowColorList: [
        "#39f",
        "#ec5707",
        "#b031d4",
        "#22e6c7",
        "#92d819",
        "#14d7f1",
        "#e23c66",
      ], //阴影颜色列表
      directionList: ["leftTop", "leftBottom", "rightTop", "rightBottom"], //星星运行方向
      speed: 50, //星星运行速度
      last_star_created_time: new Date(), //上次重绘星星时间
      Ball: class Ball {
        constructor(radius) {
          this.x = 0;
          this.y = 0;
          this.radius = radius;
          this.color = "";
          this.shadowColor = "";
          this.direction = "";
        }

        draw(context) {
          context.save();
          context.translate(this.x, this.y);
          context.lineWidth = this.lineWidth;
          var my_gradient = context.createLinearGradient(0, 0, 0, 8);
          my_gradient.addColorStop(0, this.color);
          my_gradient.addColorStop(1, this.shadowColor);
          context.fillStyle = my_gradient;
          context.beginPath();
          context.arc(0, 0, this.radius, 0, Math.PI * 2, true);
          context.closePath();

          context.shadowColor = this.shadowColor;
          context.shadowOffsetX = 0;
          context.shadowOffsetY = 0;
          context.shadowBlur = 10;

          context.fill();
          context.restore();
        }
      }, //工厂模式定义Ball类
      width: window.innerWidth,
      height: window.innerHeight,
      loginForm: {
        userName: "",
        passWord: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 2, max: 30, message: "用户名错误", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    // new Vconsole();
    this.canvas = document.getElementById("myCanvas");
    this.context = this.canvas.getContext("2d");
    this.createStar(true);
    this.drawFrame();
  },
  created() {},
  methods: {
    ...mapMutations(["changeLogin"]),

    //提交登陆1
    submitForm(formName) {
      let me = this;

      let data = {
        account: me.loginForm.userName,
        grantType: "password",
        password: me.loginForm.passWord,
        tenantId: "000000",
      };
      me.$refs[formName].validate((valid) => {
        if (valid) {
          let url = `${
            Config.host
          }/blade-auth/oauth/token?tenantId=000000&username=${
            me.loginForm.userName
          }&password=${me.$md5(
            me.loginForm.userName + me.loginForm.passWord
          )}&grant_type=password&scope=all`;
          me.$api
            .post(url)
            .then((res) => {
              me.userToken = "bearer " + res.access_token || "";
              me.changeLogin({ Authorization: me.userToken });
              let paramsInfo = {
                userId: res.user_id,
                userName: res.user_name,
              };
              window.localStorage.setItem(
                "paramsInfo",
                JSON.stringify(paramsInfo)
              );
              me.$router.replace({
                name: "mapInfo",
              });
            })
            .catch((err) => {
              this.$Dialog({
                message: err.msg,
              });
            });
        } else {
          console.log("错误登陆");
          return false;
        }
      });
    },
    //重复动画
    drawFrame() {
      let animation = requestAnimationFrame(this.drawFrame);
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.createStar(false);
      this.stars.forEach(this.moveStar);
    },
    //展示所有的星星
    createStar(params) {
      let now = new Date();
      if (params) {
        //初始化星星
        for (var i = 0; i < 50; i++) {
          const radius = Math.random() * 3 + 2;
          let star = new this.Ball(radius);
          star.x = Math.random() * this.canvas.width + 1;
          star.y = Math.random() * this.canvas.height + 1;
          star.color = "#ffffff";
          star.shadowColor =
            this.shadowColorList[
              Math.floor(Math.random() * this.shadowColorList.length)
            ];
          star.direction =
            this.directionList[
              Math.floor(Math.random() * this.directionList.length)
            ];
          this.stars.push(star);
        }
      } else if (!params && now - this.last_star_created_time > 3000) {
        //每隔3秒重绘修改颜色其中30个球阴影颜色
        for (var i = 0; i < 30; i++) {
          this.stars[i].shadowColor =
            this.shadowColorList[
              Math.floor(Math.random() * this.shadowColorList.length)
            ];
        }
        this.last_star_created_time = now;
      }
    },
    //移动
    moveStar(star, index) {
      if (star.y - this.canvas.height > 0) {
        //触底
        if (Math.floor(Math.random() * 2) === 1) {
          star.direction = "leftTop";
        } else {
          star.direction = "rightTop";
        }
      } else if (star.y < 2) {
        //触顶
        if (Math.floor(Math.random() * 2) === 1) {
          star.direction = "rightBottom";
        } else {
          star.direction = "leftBottom";
        }
      } else if (star.x < 2) {
        //左边
        if (Math.floor(Math.random() * 2) === 1) {
          star.direction = "rightTop";
        } else {
          star.direction = "rightBottom";
        }
      } else if (star.x - this.canvas.width > 0) {
        //右边
        if (Math.floor(Math.random() * 2) === 1) {
          star.direction = "leftBottom";
        } else {
          star.direction = "leftTop";
        }
      }
      if (star.direction === "leftTop") {
        star.y -= star.radius / this.speed;
        star.x -= star.radius / this.speed;
      } else if (star.direction === "rightBottom") {
        star.y += star.radius / this.speed;
        star.x += star.radius / this.speed;
      } else if (star.direction === "leftBottom") {
        star.y += star.radius / this.speed;
        star.x -= star.radius / this.speed;
      } else if (star.direction === "rightTop") {
        star.y -= star.radius / this.speed;
        star.x += star.radius / this.speed;
      }
      star.draw(this.context);
    },
  },
};
</script>

<style type="text/css" lang='less' scoped>
.login {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100vh;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: left top;
  background-color: #242645;
  color: #fff;
  font-family: "Source Sans Pro";
  /*background-size: 100%;*/
  background-image: url("../../public/images/loginBg.png");
  position: relative;

  .bgd {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .loginBox {
    width: 240px;
    height: 280px;
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 50px 40px 40px 40px;
    box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
    opacity: 1;
    background: linear-gradient(
      230deg,
      rgba(53, 57, 74, 0) 0%,
      rgb(0, 0, 0) 100%
    );
    // box-shadow: rgb(6, 17, 47, 0.7) 3px 3px 3px;

    .borderRadius {
      border-radius: 20px;
      border: 1px solid;
      // box-shadow:#fff 2px 2px 2px;
    }

    /deep/ .inps input {
      border: none;
      color: #ffffff;
      background-color: transparent;
      font-size: 14px;
    }

    .submitBtn {
      background-color: transparent !important;
      color: #39f !important;
      width: 200px;
      // color: #fff !important;
      // box-shadow: rgb(82, 134, 185) 3px 3px 3px;
      // background-color: #409eff!important;
      border-color: #409eff !important;
    }

    .iconfont {
      color: #fff;
    }
  }
}
</style>
