  <template>
  <div>
    <baidu-map
      :zoom="zoom"
      :auto-resize="true"
      :center="{ lng: 109.19373, lat: 27.715229 }"
      class="bm-view"
      @ready="handler"
      :style="{ height: mapHeight }"
    >
      <!-- 全景 -->
      <bm-panorama></bm-panorama>
      <!-- 交通流量图层 -->
      <bm-traffic :predictDate="predictDate"> </bm-traffic>
      <!-- 定位按钮 -->
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :showAddressBar="true"
        :autoLocation="true"
        @locationSuccess="locationSuccess"
        @locationError="errorLocation"
      ></bm-geolocation>
    </baidu-map>
    <!-- 开启顶部安全区适配 -->
    <!-- <van-nav-bar safe-area-inset-top /> -->

    <!-- 开启底部安全区适配 -->
    <van-number-keyboard safe-area-inset-bottom />
    <!-- 百度地图容器以外发生的事件 -->
    <van-tabbar
      v-model="active"
      :border="false"
      active-color="#FF6600"
      inactive-color="#6f6f6f"
    >
      <van-tabbar-item @click="onChange" icon="search"
        >箱体查询</van-tabbar-item
      >
      <van-tabbar-item @click="walkClick">
        <span>步行导航</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item @click="drivingClick">
        <span>驾车导航</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active1 : icon2.inactive1" />
        </template>
      </van-tabbar-item>

      <!-- <van-tabbar-item @click="getInfoMation">
        <span>市场空间</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active2 : icon3.inactive2" />
        </template>
      </van-tabbar-item> -->
      <van-tabbar-item @click="getInfoMation">
        <span>我</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active2 : icon3.inactive2" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <div class="loadingShow">
      <van-loading
        size="60px"
        vertical
        v-if="isLoading == true"
        type="spinner"
        color="#FF6600"
        text-color="#FF6600"
      >
        加载中
      </van-loading>
      <van-overlay :show="isLoading == true" />
    </div>

    <div class="tongzhi">
      <van-notice-bar left-icon="volume-o" :text="getWzqmapNotice" />
    </div>
    <div class="boxzy" @click="clickBox">
      <div>
        周围共发现
        <span style="color: #fe2e2e">{{ boxContentS || 0 }}</span
        >个箱体
      </div>
      <div>
        当前区域端口占用率:
        <span style="color: red">{{ boxContentY || 0 }}</span>
      </div>
    </div>
    <div class="boxListselect" v-if="boxList == true && boxContentS > 0">
      <ul v-for="(item, key) in markersList" :key="key">
        <li class="boxList" @click="selectBox(item)">
          <!-- element.portsOccupy >= element.portsTotal  红色-->
          <img
            :src="
              item.portsOccupy >= item.portsTotal
                ? imageInfo2
                : item.portsOccupy == 0 || item.portsOccupy == 1
                ? imageInfo1
                : imageInfo3
            "
            alt=""
          />
          {{ "(" + item.distance + "米" + ")" }}{{ item.gfName }}
        </li>
      </ul>
    </div>

    <!-- 个人信息弹窗 -->
    <van-action-sheet v-model="showMation" title="个人信息" close-icon="close">
      <van-divider />
      <el-row>
        <el-col :span="24">
          <div>
            <span style="fon-size: 10px; color: #a6a09f">姓名：</span
            ><span style="color: #a6a09f">{{ userName || "暂未录入" }}</span>
          </div>
        </el-col>
      </el-row>
      <div style="margin: 30px 0">
        <el-button
          plain
          type="primary"
          round
          class="submitBtn"
          @click="isUpdate"
          >修改密码
        </el-button>
      </div>
    </van-action-sheet>
    <!-- 修改密码弹框 @@-->
    <van-dialog
      v-model="isUp"
      title="密码修改"
      confirm-button-color="#FF6600"
      :before-close="onBeforeClose"
      @confirm="handleConfirm"
      show-cancel-button
    >
      <van-divider />
      <van-form label-align="center" :colon="true" ref="myform">
        <van-field
          v-model="oldPassword"
          label="旧密码"
          placeholder="请输入旧密码"
        />
        <van-field
          v-model="newPassword"
          label="新密码"
          placeholder="请输入新密码"
        />
        <van-field
          v-model="newPassword1"
          label="新密码"
          placeholder="请再次输入新密码"
        />
      </van-form>
    </van-dialog>

    <!-- 搜索框弹出 -->
    <van-action-sheet
      v-model="show"
      style="height: 90%"
      title="箱体二维码查询"
      close-icon="close"
    >
      <div>
        <div>
          <el-row>
            <el-col :span="24">
              <el-autocomplete
                style="width: 95%; font-size: 12px"
                v-model="inputName"
                :fetch-suggestions="querySearch"
                prefix-icon="el-icon-search"
                placeholder="请输入OBD名称或关键字查询"
                @select="handleSelect"
                @input="loadAll"
              >
              </el-autocomplete
            ></el-col>
          </el-row>
        </div>
        <div style="margin: 2% 0" v-if="isQrcode">
          <van-tabs
            @rendered="clickBar"
            v-model="activeName"
            animated
            v-preventReClick="1000"
            line-width="70px"
            title-active-color="#FF6600"
            title-inactive-color="#676261"
          >
            <van-tab title="光分纤箱" name="a">
              <div style="color: #676261">
                <div style="margin-top: 20px">光分纤箱二维码</div>
                <div>{{ attachedBoxQrCode }}</div>
                <div>{{ attachedBox }}</div>
                <div
                  style="margin-top: 10px; text-align: -webkit-center"
                  ref="qrcode"
                ></div>
              </div>
            </van-tab>
            <van-tab title="OBD二维码" name="b">
              <div style="color: #676261">
                <div style="margin-top: 20px">OBD二维码</div>
                <div>{{ gfQrCode }}</div>
                <div>{{ gfName }}</div>
                <div
                  style="margin-top: 10px; text-align: -webkit-center"
                  ref="qrcode"
                ></div>
              </div>
            </van-tab>
            <van-tab title="尾纤二维码" name="c">
              <div style="color: #676261" v-show="qrcodeC == true">
                <div style="margin-top: 20px">入户尾纤二维码</div>
                <div>{{ pigtailsQrCode ? pigtailsQrCode : "" }}</div>
                <div
                  style="margin-top: 10px; text-align: -webkit-center"
                  ref="qrcode"
                ></div>
              </div>
              <div style="margin: 20px 0">
                <el-button
                  @click="qrcodeAgin"
                  plain
                  type="primary"
                  round
                  class="submitBtn"
                  v-preventReClick="1000"
                  >点我获取二维码</el-button
                >
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div
          style="margin-top: 50%"
          v-if="isQrcode == false && inputName == ''"
        >
          <van-icon name="scan" color="#C0C4CC" size="100" />
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Config from "../config";
import QRCode from "qrcodejs2";
export default {
  name: "mapInfo",
  title: "地图展示",

  data() {
    return {
      btsLocationMarker: [], // 基站
      marketSpaceMarker: [], // 市场空间
      getWzqmapNotice: "",
      oldPassword: "",
      newPassword: "",
      newPassword1: "",
      readonly: false,
      isUp: false, // 修改密码按钮
      userId: "",
      userName: "",
      value2: "",
      value3: "",
      showMation: false, // 弹出个人信息框
      // centerInfo: {},
      predictDate: {}, //交通流量时间
      boxLocation: {}, // 每个箱体对应的经纬度
      locationInfo: { lat: null, lng: null },

      imageInfo2: require("../assets/images/red-corn.png"),
      imageInfo1: require("../assets/images/yellow-corn.png"),
      imageInfo3: require("../assets/images/blue-corn.png"),

      isLoading: false,
      markersListMarker: [],
      markersList: [],
      iconImage: {},
      BMap: {},
      map: {},
      boxList: false,
      boxContentS: "",
      boxContentY: "",
      windowposition: {},
      windowInfo: {},
      container: null,
      // weizhishow: false,
      longitude: "", // 二维码经度
      latitude: "", // 二维码纬度
      initLocation: false,
      // qrcodeInfo
      qrcodeC: false,
      isQrcode: false,
      inputName: "",
      csvS: [],
      csvList: [],
      activeName: "a",
      address: "",
      queryString: "",
      attachedBoxQrCode: "",
      gfQrCode: "",
      pigtailsQrCode: "",
      attachedBox: "",
      gfName: "",
      textInfo: "",
      render: "",
      show: false,
      active: 0,
      icon: {
        active: "https://i.loli.net/2021/03/03/cQZMEU61TRVdSPk.png",
        inactive: "https://i.loli.net/2021/03/03/Q5YF1RGHjm3NizW.png",
      },
      icon2: {
        active1: "https://i.loli.net/2021/03/03/N65BESuPycJDf9t.png",
        inactive1: "https://i.loli.net/2021/03/03/2qHn7DO3GA9sMYp.png",
      },
      icon3: {
        inactive2: "https://i.loli.net/2021/03/10/LRtUy9nKwvaBk7F.png",
        active2: "https://i.loli.net/2021/03/10/AEz2uSMR3Nv7TBY.png",
      },

      mapHeight: 0 + "px",
      zoom: 16,
    };
  },
  watch: {
    // $route: "getParams", ////监测路由变化，只要变化了就调用路由参数方法将数据存储本组件即可
    inputName(v, o) {
      if (v.length <= o.length) {
        this.isLoading = false;
      } else {
        this.isLoading = true;
      }
    },
  },
  created() {
    this.getTime();
    this.initMapHeight();
  },
  mounted() {
    let me = this;
    me.tongzhi();
    // 将所有 Toast 的展示时长设置为 1100 毫秒
    me.$toast.setDefaultOptions({ duration: 1100 });
    // 将所有 loading Toast 设置为背景不可点击
    me.$toast.setDefaultOptions("loading", { forbidClick: true });
    me.getParams();
    setTimeout(function () {
      me.getlocationGf();
    }, 1000);
    window.onresize = () => {
      return (() => {
        me.mapHeight = window.innerHeight + "px";
      })();
    };
  },
  methods: {
    // 加载通知栏内容
    tongzhi() {
      let me = this;
      let url = `${Config.host}/trdx-cxgzs/api/getWzqmapNotice`;
      let data = {};
      me.$api
        .post(url)
        .then((res) => {
          if (res.code === 200) {
            me.getWzqmapNotice =
              res.data ||
              "通知：暂无公告！请关注最新消息动态！！！！！！！！！";
          } else {
            me.$toast(res.msg);
          }
        })
        .catch((r) => {
          me.getWzqmapNotice =
            "通知：暂无公告！请关注最新消息动态！！！！！！！！！";
        });
    },
    onBeforeClose(action, done) {
      let me = this;
      if (action === "confirm") {
        return done(false);
      } else {
        me.oldPassword = "";
        me.newPassword = "";
        me.newPassword1 = "";
        return done();
      }
    },
    // 点击修改密码
    handleConfirm() {
      let me = this;

      if (me.oldPassword == "") {
        me.$toast("旧密码不能为空");
        return;
      } else if (me.newPassword == "" || me.newPassword1 == "") {
        me.$toast("新密码不能为空");
        return;
      } else if (me.newPassword !== me.newPassword1) {
        me.$toast("两次密码输入不一致");
        return;
      } else {
        let oldPassword = me.$md5(me.userName + me.oldPassword);
        let newPassword = me.$md5(me.userName + me.newPassword);
        let newPassword1 = me.$md5(me.userName + me.newPassword1);
        let url = `${Config.host}/blade-user/update-password?oldPassword=${oldPassword}&newPassword=${newPassword}&newPassword1=${newPassword1}`;
        let data = {
          // userId: me.userId,
          oldPassword: me.oldPassword,
          newPassword: me.newPassword,
          newPassword1: me.newPassword1,
        };
        me.$api
          .post(url)
          .then((res) => {
            if (res.code === 200) {
              me.$toast({
                message: "密码修改成功",
                icon: "like-o",
              });
              me.isUp = false;
              me.showMation = false;
              me.oldPassword = "";
              me.newPassword = "";
              me.newPassword1 = "";
            } else {
              me.$toast(res.msg);
            }
          })
          .catch((r) => {
            me.$toast("原密码不正确!");
          });
      }
    },
    //触发修改内容
    isUpdate() {
      let me = this;
      me.isUp = true;
      me.showMation = false;
    },
    // 从登录页面获取登录userId
    getParams() {
      let me = this;
      let data = JSON.parse(window.localStorage.getItem("paramsInfo"));
      if (data) {
        me.userId = data.userId;
        me.userName = data.userName;
      }
    },
    // 点击个人信息
    getInfoMation() {
      let me = this;
      me.showMation = true;
      // let url = `${Config.host}/blade-user/info2`;
      // let data = {
      //   userId: me.userId,
      // };
      // me.$api.post(url, data).then((res) => {
      //   if (res.data === 200) {
      //     console.log("userId1223233333", res.data);
      //   }
      // });
    },

    // 首次加载定位
    getlocationGf() {
      let me = this;
      let geolocation = new me.BMap.Geolocation();
      geolocation.getCurrentPosition(
        function (r) {
          // console.log("首次定位", r.point);
          if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            me.map.setZoom(16);
            me.initLocation = true;
            me.locationInfo = r.point;
            me.setCircle(r.point);
          } else {
            switch (this.getStatus()) {
              case 2:
                me.$toast("位置结果未知 获取位置失败.");
                break;
              case 3:
                me.$toast("导航结果未知 获取位置失败..");
                break;
              case 4:
                me.$toast("非法密钥 获取位置失败.");
                break;
              case 5:
                me.$toast("对不起,非法请求位置  获取位置失败.");
                break;
              case 6:
                me.$toast("对不起,当前 没有权限 获取位置失败.");
                break;
              case 7:
                me.$toast("对不起,服务不可用 获取位置失败.");
                break;
              case 8:
                me.$toast("对不起,请求超时 获取位置失败.");
                break;
            }
          }
          // console.log("rrrr", r);
        },
        { enableHighAccuracy: true }
      );
    },

    // 获取当前时间
    getTime() {
      let me = this;
      let now = new Date();
      let day = now.getDay();
      let weeks = new Array(7, 1, 2, 3, 4, 5, 6); // 星期日到星期一
      let week = weeks[day];
      me.predictDate.weekday = week;
      me.predictDate.hour = now.getHours();
      me.predictDate.minute = now.getMinutes();
      // console.log("predictDate", me.predictDate);
    },

    // 步行路线规划
    // getWalking(endInfo) {
    //   let me = this;
    //   if (JSON.stringify(me.boxLocation) == "{}") {
    //     me.$toast("请点击需要规划步行路线的箱体！");
    //     return;
    //   } else if (me.initLocation == false) {
    //     me.$toast("请点击右下角按钮获取当前位置！");
    //     return;
    //   }
    //   let startInfo = me.locationInfo;
    //   //获取两点距离,保留小数点后两位
    //   let start = new me.BMap.Point(startInfo.lng, startInfo.lat); //起点
    //   let end = new me.BMap.Point(endInfo.lng, endInfo.lat); //终点
    //   // if (me.map.getDistance(start, end) > 100000) {
    //   //   me.$toast("距离大于10公里，请更换其他导航方式!");
    //   //   return;
    //   // } else {
    //   //   me.map.clearOverlays();
    //   // }
    //   //WalkingRoute ,绘制步行路线
    //   let transit = new me.BMap.WalkingRoute(me.map, {
    //     renderOptions: { map: me.map, autoViewport: true },
    //     onSearchComplete: function (results) {
    //       if (transit.getStatus() != BMAP_STATUS_SUCCESS) {
    //         return;
    //       }
    //       if (results.getPlan(0).getDistance(false) > 100000) {
    //         me.$toast("距离大于100公里，请更换其他导航方式!");
    //         return;
    //       } else {
    //         me.map.clearOverlays();
    //       }

    //       me.$toast(
    //         `步行时间:${results
    //           .getPlan(0)
    //           .getDuration(true)}，总路程${results
    //           .getPlan(0)
    //           .getDistance(true)}`
    //       );
    //     },
    //   });
    //   transit.search(start, end);
    //   setTimeout(function () {
    //     me.map.setViewport([start, end]); // 调整到最佳视野
    //     console.log("zomm", me.map.getZoom());
    //   }, 1000);
    //   transit.enableAutoViewport();
    // },

    // 驾车路线规划
    // getCar(endInfo) {
    //   let me = this;
    //   if (JSON.stringify(me.boxLocation) == "{}") {
    //     me.$toast("请点击需要规划驾车路线的箱体！");
    //     return;
    //   } else if (me.initLocation == false) {
    //     me.$toast("请点击右下角按钮获取当前位置！");
    //     return;
    //   }

    //   let startInfo = me.locationInfo;

    //   //获取两点距离,保留小数点后两位
    //   let start = new me.BMap.Point(startInfo.lng, startInfo.lat); //起点
    //   let end = new me.BMap.Point(endInfo.lng, endInfo.lat); //终点
    //   // if (me.map.getDistance(start, end) > 100000) {
    //   //   me.$toast("距离大于10000公里，请更换其它导航方式!");
    //   //   return;
    //   // } else {
    //   //   me.map.clearOverlays();
    //   // }

    //   //驾车路线规划
    //   let transitCar = new me.BMap.DrivingRoute(me.map, {
    //     renderOptions: {
    //       map: me.map,
    //       autoViewport: true,
    //       policy: "BMAP_DRIVING_POLICY_LEAST_TIME",
    //     },
    //     onSearchComplete: function (results) {
    //       if (transitCar.getStatus() != BMAP_STATUS_SUCCESS) {
    //         return;
    //       }
    //       if (results.getPlan(0).getDistance(false) > 1000000) {
    //         console.log("驾车距离1：", results.getPlan(0).getDistance(false));
    //         me.$toast("距离大于1000公里，请更换其它导航方式!");
    //         return;
    //       } else {
    //         me.map.clearOverlays();
    //       }
    //       me.$toast(
    //         `驾车时间:${results
    //           .getPlan(0)
    //           .getDuration(true)}，总路程${results
    //           .getPlan(0)
    //           .getDistance(true)}`
    //       );
    //       setTimeout(function () {
    //         me.map.setViewport([end, start]); // 调整到最佳视野
    //         console.log("zomm", me.map.getZoom());
    //       }, 1000);
    //     },
    //   });
    //   transitCar.search(start, end);
    // },
    // 跳转百度APP进行步行导航
    walkClick() {
      let me = this;
      if (JSON.stringify(me.boxLocation) == "{}") {
        me.$toast({
          message: "请点击箱体",
          icon: "shop-collect-o",
        });
        return;
      } else if (me.initLocation == false) {
        me.$toast("请点击右下角按钮获取当前位置！");
        return;
      } else {
        window.location.href =
          "http://api.map.baidu.com/direction?origin=latlng:" +
          `${me.locationInfo.lat}` +
          "," +
          `${me.locationInfo.lng}` +
          "|name:我的位置&destination=" +
          `${me.boxLocation.lat}` +
          "," +
          `${me.boxLocation.lng}` +
          "&mode=walking&region=初始位置&output=html&src=webapp.demo.location";
      }
      // 直接调用百度地图app
      // window.location.href =
      //   "baidumap://map/marker?location=28.080628,108.491287&title=我的位置&content=''&src";
    },
    // 跳转百度APP进行驾车导航
    drivingClick() {
      let me = this;
      if (JSON.stringify(me.boxLocation) == "{}") {
        me.$toast({
          message: "请点击箱体",
          icon: "gift-o",
        });
        return;
      } else if (me.initLocation == false) {
        me.$toast("请点击右下角按钮进行当前位置定位！");
        return;
      } else {
        window.location.href =
          "http://api.map.baidu.com/direction?origin=latlng:" +
          `${me.locationInfo.lat}` +
          "," +
          `${me.locationInfo.lng}` +
          "|name:我的位置&destination=" +
          `${me.boxLocation.lat}` +
          "," +
          `${me.boxLocation.lng}` +
          "&mode=driving&region=初始位置&output=html&src=webapp.demo.location";
      }
    },
    // 选择地图列表重新展示
    selectBox(item) {
      let me = this;
      // me.weizhishow = true;
      me.map.clearOverlays();
      me.boxList = false;
      let myIcon0 = {};
      let selectBoxGf = new me.BMap.Point(item.longitude, item.latitude);
      if (item.portsOccupy == 0 || item.portsOccupy == 1) {
        myIcon0 = new me.BMap.Icon(
          require("../assets/images/yellow-corn-m.png"),
          new me.BMap.Size(16, 25),
          {
            imageSize: new me.BMap.Size(15, 25),
          }
        );
      } else if (item.portsOccupy >= item.portsTotal) {
        myIcon0 = new me.BMap.Icon(
          "http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png",
          new me.BMap.Size(16, 25),
          {
            imageSize: new me.BMap.Size(15, 25),
          }
        );
      } else {
        myIcon0 = new me.BMap.Icon(
          "http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          new me.BMap.Size(16, 25),
          {
            imageSize: new me.BMap.Size(15, 25),
          }
        );
      }

      me.map.panTo(selectBoxGf, 16);
      let mk = new me.BMap.Marker(
        { lng: item.longitude, lat: item.latitude },
        { icon: myIcon0 }
      );
      me.map.addOverlay(mk);

      let opts = {
        width: 80, // 信息窗口宽度
        height: 110, // 信息窗口高度
      };
      let contentInfo = `<div class="boxWindowInfo">
                            <div>OBD名称：${item.gfName}</div>
                            <div>端口总数：${item.portsTotal}</div>
                            <div>端口占用数：${item.portsOccupy}</div>
                            <div>所属箱体：${item.attachedBox}</div>
                            <div style="margin-top:4px;float:right;">
                                        <button style="background-color: #f56c6c;
                                                      border-color: #f56c6c;
                                                      border-radius:5px;
                                                      box-sizing: border-box;
                                                      outline: none;"
                                                type="button" id="btn2">
                                          <span style="color:#fff">经纬度纠正</span>
                                        </button>         
                                    </div>                       
                         </div>`;
      // <div style="margin-top:4px">
      //                    <input type="button" id="btnWalcking" value="步行路线">
      //  <input type="button" id="btnCar" value="驾车路线"></div>
      let infoWindow = new me.BMap.InfoWindow(contentInfo, opts);
      if (!infoWindow.isOpen()) {
        infoWindow.addEventListener("open", function (e) {
          document.getElementById("btn2").onclick = function () {
            me.$dialog
              .alert({
                // title: "Hello World!", //加上标题
                message:
                  "提醒：纠正经纬度操作需要赋权，若没有权限将自动弹回登录页，权限申请请联系系统管理员。是否需要继续？", //改变弹出框的内容
                showCancelButton: true, //展示取消按钮
                confirmButtonText: "继续",
              })
              .then(() => {
                //点击确认按钮后的调用
                me.isLoading = true;
                me.locationSuccess(e); // 实时定位当前位置
                console.log("获取当前定位的经纬度eeee", e);
                let url = `${Config.host}/trdx-cxgzs/api/updateLonLat`;
                let data = {
                  longitude: me.locationInfo.lng,
                  latitude: me.locationInfo.lat,
                  gfCode: item.gfCode,
                };
                me.$api
                  .post(url, data)
                  .then((res) => {
                    if (res.code === 200) {
                      me.isLoading = false;
                      me.$toast({
                        message: "纠正成功",
                        icon: "like-o",
                      });
                      infoWindow.isOpen();
                    }
                  })
                  .catch((err) => {
                    me.isLoading = false;
                    console.log(err);
                    me.$toast("请求出错，重试！");
                  });
              })
              .catch(() => {
                //点击取消按钮后的调用
                console.log("点击了取消按钮噢");
              });
          };
          // document.getElementById("btnCar2").onclick = function () {
          //   me.getCar(point);
          // };
        });
      } else {
        let btn = document.getElementById("btn2");
        btn.onclick = function () {
          alert("错误提示，不能纠正");
        };
      }
      // if (!infoWindow.isOpen()) {
      //   infoWindow.addEventListener("open", function (e) {
      //     document.getElementById("btnWalcking").onclick = function () {
      //       me.getWalking(selectBoxGf);
      //     };
      //     document.getElementById("btnCar").onclick = function () {
      //       me.getCar(selectBoxGf);
      //     };
      //   });
      // } else {
      //   let btn = document.getElementById("btnWalcking");
      //   btn.onclick = function () {
      //     me.$toast("错误，请重试");
      //   };
      // }
      mk.addEventListener("click", function (e) {
        me.map.openInfoWindow(infoWindow, selectBoxGf);
      });
      me.boxLocation = selectBoxGf;
      me.map.openInfoWindow(infoWindow, selectBoxGf);
    },
    // 点击占用率显示列表
    clickBox() {
      let me = this;
      me.boxList = !me.boxList;
    },
    // 定位失败
    errorLocation(e) {
      let me = this;
      console.log("定位失败返回的参数", e);
      me.$tost("请开启定位功能");
    },
    // 定位按钮获取经纬度
    locationSuccess(e) {
      let me = this;
      me.map.setZoom(16);
      me.initLocation = true;
      me.locationInfo.lat = e.point.lat;
      me.locationInfo.lng = e.point.lng;
      console.log("点击按钮第二次定位lat", e.point.lat);
      console.log("点击按钮第二次定位lng", e.point.lng);
      me.$toast({
        message: "位置定位成功",
        icon: "like-o",
      });
      // me.map.clearOverlays();
      me.setCircle(e.point);
      console.log("点击按钮得到的地图层级", me.map.getZoom());
      console.log("点击按钮得到的经纬度", me.locationInfo);
      console.log("地图是否初始化", me.map.loaded);
    },
    // 地图点击事件
    clickMap(e) {
      let me = this;
      me.getTime();
      // me.map.clearOverlays();
      // me.map.disableDragging(); // 关闭标注拖拽功能
      // me.markersList = [];
      me.setCircle(e.point);
    },
    // 组合圆圈和标注方法
    setCircle(item) {
      let me = this;
      me.map.clearOverlays();
      me.map.setZoom(16);
      me.isLoading = true;
      // 渲染圆圈
      let opts = {
        strokeWeight: "0.2",
        strokeOpacity: "0.5",
        fillColor: "green",
        fillOpacity: "0.1",
        strokeStyle: "dashed",
      };

      let circleGf = new me.BMap.Point(item.lng, item.lat);
      me.map.panTo(circleGf, 16);
      let circleInfo = new me.BMap.Circle(item, 500, opts);
      me.map.addOverlay(circleInfo);
      let url = `${Config.host}/trdx-cxgzs/api/getLocation`; // ?lon=${item.lng}&lat=${item.lat}
      let data = {
        lon: item.lng + "",
        lat: item.lat + "",
      };
      me.$api.post(url, data).then((res) => {
        if (res.code === 200) {
          me.isLoading = false;
          let resData = res.data.gfLocation || [];
          me.markersList = resData.slice(1, resData.length); // 列表
          me.markersListMarker = resData.slice(1, 500); // 取前500条设置点坐标  OBD箱体
          me.btsLocationMarker = res.data.btsLocation; // 基站
          me.marketSpaceMarker = res.data.marketSpace; //  市场空间
          if (JSON.stringify(me.locationInfo) == "{}") {
            me.$toast("定位未开启，请重新定位！");
            return;
          }
          if (resData.toString().length == 0) {
            me.$toast({
              message: "此处无箱体",
              icon: "flower-o",
            });
          }
          me.boxContentS = resData.length - 1;
          me.boxContentY = resData[0].portsOccupyRate; // 默认取第一条箱体占用率
          let circleGfInfo = new me.BMap.Point(item.lng, item.lat);

          let myIcon3 = new me.BMap.Icon(
            "http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            new me.BMap.Size(16, 25),
            {
              imageSize: new me.BMap.Size(15, 25),
            }
          );

          let myIcon4 = new me.BMap.Icon(
            "http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png",
            new me.BMap.Size(16, 25),
            {
              imageSize: new me.BMap.Size(15, 25),
            }
          );
          let myIcon5 = new me.BMap.Icon(
            require("../assets/images/yellow-corn-m.png"),
            new me.BMap.Size(16, 25),
            {
              imageSize: new me.BMap.Size(15, 25),
            }
          );
          // 基站图标
          let myIcon6 = new me.BMap.Icon(
            require("../assets/images/jizhan1.png"),
            new me.BMap.Size(22, 22),
            {
              imageSize: new me.BMap.Size(22, 22),
            }
          );
          // 市场空间图标
          let myIcon7 = new me.BMap.Icon(
            require("../assets/images/shichang2.png"),
            new me.BMap.Size(35, 35),
            {
              imageSize: new me.BMap.Size(35, 35),
            }
          );
          let iconCenter = new me.BMap.Icon(
            require("../assets/images/centerG.png"),
            new me.BMap.Size(25, 30),
            {
              imageSize: new me.BMap.Size(25, 30),
            }
          );
          let markeInfo = new me.BMap.Marker(circleGfInfo, {
            icon: iconCenter,
          });
          me.map.addOverlay(markeInfo);
          // 地图列表渲染坐标点
          // OBD箱体窗体大小
          let optsList = {
            width: 80, // 信息窗口宽度
            height: 110, // 信息窗口高度
          };
          // 基站窗体大小
          let optsList1 = {
            width: 80, // 信息窗口宽度
            height: 20, // 信息窗口高度
          };
          // 市场空间窗体大小
          let optsList2 = {
            width: 80, // 信息窗口宽度
            height: 110, // 信息窗口高度
          };
          let setIcon = {};
          // ###箱体###描点
          me.markersListMarker.forEach((element) => {
            if (element.portsOccupy >= element.portsTotal) {
              // 端口占用数>=总数 显示红色
              setIcon = myIcon4;
            } else if (element.portsOccupy == 0 || element.portsOccupy == 1) {
              //端口占用数>=总数 显示黄色
              setIcon = myIcon5;
            } else {
              // 显示默认蓝色
              setIcon = myIcon3;
            }
            let circleGfInfo2 = new me.BMap.Point(
              element.longitude,
              element.latitude
            );
            let markeInfo2 = new me.BMap.Marker(circleGfInfo2, {
              icon: setIcon,
            });
            me.map.addOverlay(markeInfo2);
            let point = new me.BMap.Point(element.longitude, element.latitude);
            let contentInfo = `<div class="boxWindowInfo">
                                    <div>OBD名称：${element.gfName}</div>
                                    <div>端口总数：${element.portsTotal}</div>
                                    <div>端口占用数：${element.portsOccupy}</div>
                                    <div>所属箱体：${element.attachedBox}</div>                             
                                </div>`;
            // 经纬度纠正
            // <div style="margin-top:4px;float:right;">
            //         <button style="background-color: #f56c6c;
            //                       border-color: #f56c6c;
            //                       border-radius:5px;
            //                       box-sizing: border-box;
            //                       outline: none;"
            //                 type="button" id="btn2">
            //           <span style="color:#fff">经纬度纠正</span>
            //         </button>
            //     </div>

            //  <div style="margin-top:4px">
            // <input type="button" id="btn2" value="步行路线">
            // <input type="button" id="btnCar2" value="驾车路线">
            // </div>

            let infoWindow = new me.BMap.InfoWindow(contentInfo, optsList);

            // if (!infoWindow.isOpen()) {
            //   infoWindow.addEventListener("open", function (e) {
            //     document.getElementById("btn2").onclick = function () {
            //       me.$dialog
            //         .alert({
            //           // title: "Hello World!", //加上标题
            //           message:
            //             "提醒：纠正经纬度操作需要赋权，若没有权限将自动弹回登录页，权限申请请联系系统管理员。是否需要继续？", //改变弹出框的内容
            //           showCancelButton: true, //展示取消按钮
            //           confirmButtonText: "继续",
            //         })
            //         .then(() => {
            //           //点击确认按钮后的调用
            //           me.isLoading = true;
            //           me.locationSuccess(e); // 实时定位当前位置
            //           console.log("获取当前定位的经纬度eeee", e);
            //           let url = `${Config.host}/trdx-cxgzs/api/updateLonLat`;
            //           let data = {
            //             longitude: me.locationInfo.lng,
            //             latitude: me.locationInfo.lat,
            //             gfCode: element.gfCode,
            //           };
            //           me.$api
            //             .post(url, data)
            //             .then((res) => {
            //               if (res.code === 200) {
            //                 me.isLoading = false;
            //                 me.$toast({
            //                   message: "纠正成功",
            //                   icon: "like-o",
            //                 });
            //                 infoWindow.isOpen();
            //               }
            //             })
            //             .catch((err) => {
            //               me.isLoading = false;
            //               console.log(err);
            //               me.$toast("请求出错，重试！");
            //             });
            //         })
            //         .catch(() => {
            //           //点击取消按钮后的调用
            //           console.log("点击了取消按钮噢");
            //         });
            //     };
            //     // document.getElementById("btnCar2").onclick = function () {
            //     //   me.getCar(point);
            //     // };
            //   });
            // } else {
            //   let btn = document.getElementById("btn2");
            //   btn.onclick = function () {
            //     alert("错误提示，不能纠正");
            //   };
            // }
            markeInfo2.addEventListener("click", function (e) {
              me.boxList = false;
              me.boxLocation = point;
              console.log("箱体经纬度", me.boxLocation);
              me.map.openInfoWindow(infoWindow, point);
            });
          });

          // ###基站###############描点
          me.btsLocationMarker.forEach((element) => {
            let circleGfInfo3 = new me.BMap.Point(
              element.longitude,
              element.latitude
            );
            let markeInfo3 = new me.BMap.Marker(circleGfInfo3, {
              icon: myIcon6,
            });
            me.map.addOverlay(markeInfo3);
            let point = new me.BMap.Point(element.longitude, element.latitude);
            let contentInfo = `<div class="boxWindowInfo">
                                    <div>基站小区：${element.btsEstateName}</div>                    
                                </div>`;
            let infoWindow = new me.BMap.InfoWindow(contentInfo, optsList1);
            markeInfo3.addEventListener("click", function (e) {
              me.boxList = false;
              me.boxLocation = point;
              console.log("箱体经纬度", me.boxLocation);
              me.map.openInfoWindow(infoWindow, point);
            });
          });

          // ###市场空间####################################描点
          me.marketSpaceMarker.forEach((element) => {
            let circleGfInfo4 = new me.BMap.Point(
              element.longitude,
              element.latitude
            );
            let markeInfo4 = new me.BMap.Marker(circleGfInfo4, {
              icon: myIcon7,
            });
            me.map.addOverlay(markeInfo4);
            let point = new me.BMap.Point(element.longitude, element.latitude);
            let contentInfo = `<div class="boxWindowInfo">
                                    <div>区域：${
                                      element.area +
                                      element.town +
                                      element.villageType +
                                      element.village
                                    }</div>
                                    <div>住宅数：${element.familyTotal}</div>
                                    <div>端口总数：${element.portsTotal}</div>

                                    <div>端口占用数：${
                                      element.portsOccupy
                                    }</div>
                                    <div>端口占用率：${
                                      element.portsOccupyRate
                                    }</div>
                                    <div>端口资源覆盖率：${
                                      element.portsCoverRate
                                    }</div>
                                    <div>宽带用户渗透率：${
                                      element.broadbandPermeability
                                    }</div>
                                    <div>移动宽带用户数：${
                                      element.ydUser == -1
                                        ? "未录入"
                                        : element.ydUser
                                    }</div>
                                    <div>联通宽带用户数：${
                                      element.ltUser == -1
                                        ? "未录入"
                                        : element.ydUser
                                    }</div>
                                    <div>广电宽带用户数：${
                                      element.gdUser == -1
                                        ? "未录入"
                                        : element.gdUser
                                    }</div>
                                    <div style="color:red">市场发展告警级别：${
                                      element.warningScfzLevelName
                                        ? element.warningScfzLevelName
                                        : "无"
                                    }</div>
                                    <div style="color:red">光宽资源告警级别：${
                                      element.warningGkzyLevelName
                                        ? element.warningGkzyLevelName
                                        : "无"
                                    }</div>                  
                                </div>`;

            let infoWindow = new me.BMap.InfoWindow(contentInfo, optsList2);
            me.map.openInfoWindow(infoWindow, point); // 默认打开一个窗体

            markeInfo4.addEventListener("click", function (e) {
              me.boxList = false;
              me.boxLocation = point;
              me.map.openInfoWindow(infoWindow, point);
            });
          });
        }
      });
    },

    infoWindowClose() {
      // this.weizhishow = false;
      this.boxList = true;
    },
    infoWindowOpen() {
      // this.weizhishow = true;
      this.boxList = false;
    },
    // qrcodeInfo搬迁到此页面
    // 切换二维码标签
    clickBar(name, title) {
      let me = this;
      me.creatQrCode(name);
    },

    // 再次刷新获取尾纤二维码  getPigtailsQrCode  pigtailsQrCode
    qrcodeAgin() {
      let me = this;
      me.isLoading = true;
      let url = `${Config.host}/trdx-cxgzs/api/getPigtailsQrCode`;
      let data = "";
      me.$api
        .post(url, data)
        .then((res) => {
          if (me.$refs.qrcode.innerHTML) {
            me.$refs.qrcode.innerHTML = "";
          }
          if (res.code === 200) {
            me.isLoading = false;
            me.$toast({
              message: "刷新成功",
              icon: "fire-o",
            });
            me.qrcodeC = true;
            me.pigtailsQrCode = res.data || "";
            me.getCode(me.pigtailsQrCode, me.$refs.qrcode);
          }
        })
        .catch((err) => {
          console.log(err);
          me.$toast("请求出错，重试！");
        });
    },

    loadAll() {
      let me = this;
      me.isLoading = true;
      let url = `${Config.host}/trdx-cxgzs/api/getGfNameList`;
      let data = {
        gfName: me.inputName,
      };
      me.$api
        .post(url, data)
        .then((res) => {
          me.csvList = [];
          me.csvS = [];
          if (this.inputName == "") {
            me.isLoading = false;
            me.isQrcode = false;
            return;
          }
          if (res.code === 200) {
            me.isLoading = false;
            me.csvList = res.data || [];
            me.csvS = [];
            let len = me.csvList.length;
            let arr = [];
            for (let i = 0; i < len; i++) {
              arr.push(me.csvList[i]);
            }

            for (let i = 0; i < arr.length; i++) {
              let obj = { value: "" };
              obj.value = arr[i];
              me.csvS.push(obj);
            }
          }
        })
        .catch((err) => {
          me.$toast("操作失败，请重试！");
        });
    },

    querySearch(queryString, cb) {
      let me = this;
      me.queryString = queryString;
      let csvS = me.csvS;
      cb(csvS);
    },
    // 选择二维码列表
    handleSelect(item) {
      let me = this;
      me.isLoading = true;
      me.isQrcode = true;
      me.address = item;
      me.boxList = true;
      me.creatQrCode("a");
      let url = `${Config.host}/trdx-cxgzs/api/getQrCode`;
      let data = {
        gfName: item.value,
      };
      me.$api.post(url, data).then((res) => {
        if (res.code === 200) {
          let resDD = res.data;
          me.isLoading = false;
          me.longitude = resDD.longitude;
          me.latitude = resDD.latitude;
          me.boxList = true;
          let item = {
            lng: me.longitude,
            lat: me.latitude,
          };
          me.setCircle(item);
        }
      });
    },

    creatQrCode(name) {
      let me = this;
      me.isLoading = true;
      let url = `${Config.host}/trdx-cxgzs/api/getQrCode`;
      let data = {
        gfName: me.inputName,
      };
      me.$api
        .post(url, data)
        .then((res) => {
          let resDataCode = res.data || "";
          if (res.code === 200 && JSON.stringify(res.data) !== "{}") {
            me.isLoading = false;
            let dataInfo = resDataCode;
            let render = "";
            if (name == "a") {
              me.textInfo = dataInfo.attachedBoxQrCode || "";
              me.attachedBoxQrCode = dataInfo.attachedBoxQrCode || "";
              me.attachedBox = dataInfo.attachedBox || "";
            }

            if (name == "b") {
              me.textInfo = dataInfo.gfQrCode || "";
              me.gfQrCode = dataInfo.gfQrCode || "";
              me.gfName = dataInfo.gfName || "";
            }
            if (name == "c") {
              me.qrcodeC = false;
              // me.textInfo = dataInfo.pigtailsQrCode || "";
              // me.pigtailsQrCode = dataInfo.pigtailsQrCode || "";
            }
            // 清除二维码

            if (me.$refs.qrcode.innerHTML) {
              me.$refs.qrcode.innerHTML = "";
            }
            me.getCode(me.textInfo, me.$refs.qrcode);
          }
        })
        .catch((err) => {
          me.$toast("服务错误，请重试！");
        });
    },
    //获取二维码
    getCode(textInfo, render) {
      let qrcode = new QRCode(render, {
        text: textInfo, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },

    // 点击导航栏搜索弹框
    onChange() {
      let me = this;
      me.show = true;
      me.boxList = false;
    },

    initMapHeight() {
      if (document.documentElement) {
        this.mapHeight = document.documentElement.clientHeight + "px";
      } else {
        let main = document.getElementsByClassName("bm-view");
        main.style.height = window.innerHeight + "px";
        // this.mapHeight = window.innerHeight + "px";
      }
    },
    handler({ BMap, map }) {
      let me = this; // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      me.BMap = BMap;
      me.map = map;

      // 由于百度地图禁用了click事件，解决如下
      map.addEventListener("touchmove", function (e) {
        map.enableDragging(); // 启用地图拖拽，默认启用
      });
      map.addEventListener("touchend", function (e) {
        map.disableDragging(); //禁用地图拖拽
      });

      map.addEventListener("click", function (e) {
        me.boxList = false;
        if (e.overlay) {
          // 如果有覆盖物 不进行此操作
          return;
        } else {
          me.clickMap(e);
        }
      });
    },
  },
};
</script>
<style type="text/css">
/* select框的高度 */
.el-scrollbar {
  height: 190px !important;
}
.el-autocomplete-suggestion li {
  line-height: 30px !important;
}
.van-dialog__header {
  color: #ff6600 !important;
}
.van-action-sheet {
  color: #ff6600 !important;
}

.van-tabs__line {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 1;
  width: 40px;
  height: 3px;
  background-color: #ff6600 !important;
  border-radius: 3px;
}
.submitBtn {
  width: 150px;
  color: #ff6600 !important;
  background-color: white !important;
  border: 1px solid #ff6600 !important;
}
/* .BMap_bubble_title {
  color: black;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
} */
/* .content-box img{
  background: '../assets/images/close.png'!important;
} */

.BMap_pop div:nth-child(1) {
  border-radius: 7px 0 0 0;
}
.BMap_pop div:nth-child(3) {
  border-radius: 0 7px 0 0;
}
.BMap_pop div:nth-child(3) div {
  border-radius: 7px;
}
.BMap_pop div:nth-child(5) {
  border-radius: 0 0 0 7px;
}
.BMap_pop div:nth-child(5) div {
  border-radius: 7px;
}
.BMap_pop div:nth-child(7) {
  border-radius: 0 0 7px 0;
}
.BMap_pop div:nth-child(7) div {
  border-radius: 7px;
}

.van-overlay {
  opacity: 0 !important;
  background-color: #fffdfd !important;
}

/* 消息内容 */
.BMap_bubble_content {
  background-color: white;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.boxWindowInfo {
  width: 100%;
  height: 100px;
  overflow: scroll;
  font-size: 10px;
  color: #6f6f6f;
}

.bm-view {
  width: 100%;
  height: 850px;
}
.anchorBR {
  position: absolute !important;
  right: 20px !important;
  bottom: 100px !important;
  height: 32px;
  z-index: 1200;
  text-size-adjust: none;
  inset: auto 10px 100px auto !important;
}

.content {
  padding: 10px 10px 100px;
}
.boxzy {
  width: 10rem;
  position: absolute;
  font-size: 12px;
  padding: 6px 10px;
  left: 10px;
  top: 30px;
  text-align: left;
  cursor: pointer;
  font-family: arial, sans-serif;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 3px;
  z-index: 0;
  text-size-adjust: none;
  border-radius: 10px;
  color: #6f6f6f;
  background-color: whitesmoke;
}
.tongzhi {
  width: 100%;
  position: absolute;
  font-size: 12px;
  left: 0;
  top: 0;
  text-align: left;
  /* cursor: pointer; */
  font-family: arial, sans-serif;
  z-index: 0;
  text-size-adjust: none;
}
.van-notice-bar {
  background-color: transparent !important;
  height: 30px !important;
}
.anchorTR {
  inset: auto 10px auto auto !important;
  position: absolute !important;
  right: 10px !important;
  top: 26px !important;
  height: 32px;
  z-index: 1200;
  text-size-adjust: none;
  border-radius: 10px !important;
}
.pano_close {
  right: 10px !important;
  top: 30px !important;
}
.loadingShow {
  position: absolute;
  left: 40%;
  bottom: 20%;
  z-index: 999999;
}
.boxListselect {
  width: 10rem;
  height: 220px;
  overflow: scroll;
  position: absolute;
  font-size: 12px;
  padding: 6px 10px;
  left: 10px;
  top: 77px;
  text-align: left;
  font-family: arial, sans-serif;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 3px;
  background-color: whitesmoke;
  color: #6f6f6f;
  text-size-adjust: none;
  border-radius: 10px;
}
.boxList {
  font-size: 12px;
  border-bottom: 1px dashed rgb(221, 220, 220);
  vertical-align: middle;
}
.boxList img {
  width: 10px;
  height: 10px;
}
</style>>
