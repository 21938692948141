//SessionStorage 取楚
export const getSessionStorage = function() {
    console.log('取出')
}

// 获取地址栏参数
export const getUrlParams = function() {
    var param = {}
    window.location.search.replace(/\?/g, '').split('&').map(it => {
        var p = it.split('=')
        param[p[0]] = p[1]
    })
    return param
}