export default {
    install(Vue) {
        Vue.directive('preventReClick', {
            inserted(van, binding) {
                van.addEventListener('click', () => {
                    if (!van.disabled) {
                        van.disabled = true;
                        setTimeout(() => {
                            van.disabled = false;
                        }, binding.value || 500);
                    }
                })
            }
        })
    }
}