export default {
    method: 'post',
    // 基础url前缀
    baseURL: '',
    // 请求头信息
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
    },
    // 参数
    data: {},
    //配置axios跨域请求
    // devServer: {
    //     proxy: {
    //         '/api': { //访问路径，可以自己设置，
    //             target: 'http://135.0.137.4:82', //代理接口，即后端运行所在的端口
    //             changeOrigin: true, //设置是否跨域
    //             ws: true,
    //             pathRewrite: { //访问路径重写
    //                 '^/api': ''
    //             }
    //         }
    //     }
    // },

    // 设置超时时间
    timeout: 10000,
    // 携带凭证
    withCredentials: false,
    // 返回数据类型
    responseType: 'json'
}