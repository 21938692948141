import Config from '@/config'
import Vue from 'vue'

/**
 * 时间格式化
 */
Vue.filter('datetime', function(value, format) {
    if (!value) return ''
    return (new Date(value)).Format(format || 'yyyy-MM-dd hh:mm:ss')
})

Vue.filter('imageFormat', function(value) {
    if (value && (value.match(/^http/) || value.match(/^\/\//))) {
        return value
    }
    return Config.imageHost + value;
});